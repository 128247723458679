import axios from "axios";
import auth from "@/store/auth";

const createAxiosInstance = (baseURL) => {
  const axiosInstance = axios.create({ baseURL });
  
  const token = auth.state.token;
  if (token) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  
  return axiosInstance;
};

export const retryApi = () => {
  return createAxiosInstance(process.env.VUE_APP_AXIOS_RETRY_URL);
};

export default () => createAxiosInstance(process.env.VUE_APP_AXIOS_BASE_URL);